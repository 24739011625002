import React, { useState } from "react";
import { useRealmApp } from "../realm_app";
import { Button } from "react-bootstrap";
import { navigate } from "gatsby";

const InviteMembersElm = () => {
  return (
    <div>
      Step 1: Invite your circle members to MentorAmp.
      <br />
      <br />
      <div>
        <Button
          className="deepen-blue-button"
          onClick={() => {
            navigate("/facilitator/signup/invite_members/");
          }}
        >
          Get Invite Link
        </Button>
      </div>
    </div>
  );
};

const MatchMembersElm = () => {
  return (
    <div>
      Step 2: Match your members. You can also{" "}
      <a href="/facilitator/signup/invite_members">invite more members.</a>
      <br />
      <br />
      <div>
        <Button
          className="deepen-blue-button"
          onClick={() => {
            navigate("/facilitator/dashboard/members");
          }}
        >
          Go to Match Page
        </Button>
      </div>
    </div>
  );
};

const LaunchTasksDoneElm = () => {
  return (
    <div>
      You've completed launching your circle! You can{" "}
      <a href="/facilitator/signup/invite_members">invite more members </a>
      or
      <a href="/facilitator/dashboard/members"> match members again.</a>
    </div>
  );
};

export default () => {
  const realmApp = useRealmApp();
  React.useEffect(() => {}, []);

  const customData = realmApp.app.currentUser?.customData;
  console.log(customData);
  var current_launch_task = LaunchTasksDoneElm();
  if (!Boolean(customData?.invited_members)) {
    current_launch_task = InviteMembersElm();
  } else if (!Boolean(customData?.matched_members)) {
    current_launch_task = MatchMembersElm();
  }

  return (
    <div>
      <h5 style={{ textAlign: "left" }}>Circle Launch Tasks</h5>
      <section className="light-card p-4">{current_launch_task}</section>
    </div>
  );
};
