import React, { useState } from "react";
import DashboardNav from "../../../components/navbar";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Footer } from "../../../components/footer";

import {
  RealmAppProvider,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";
import CircleProgressElm from "../../../components/facilitator/circle_progress";
import CircleLaunchTasksElm from "../../../components/facilitator/circle_launch_tasks";
import { FAC_NAVBAR_LINKS } from "../../../global";

const GroupCallElm = () => {
  return (
    <div style={{ width: "100%" }}>
      <h5>NEXT GROUP CALL</h5>
      <div style={{ backgroundColor: "#bebebe", padding: "10px" }}>
        9:00 - 10:00 am PDT, Saturday, July 25, 2020
      </div>
    </div>
  );
};

const DashboardHome = () => {
  return (
    <>
      <div className="page">
        <DashboardNav
          link="/facilitator/dashboard"
          menuItems={FAC_NAVBAR_LINKS}
          settingsLink="/facilitator/dashboard/settings"
        />
        <br />
        <br />
        <section className="tasks">
          <CircleLaunchTasksElm />
          <br />
          <CircleProgressElm />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <DashboardHome />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
