import React, { useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";

import { useRealmApp } from "../realm_app";
import { getCircleStartTime, getRelationTitle } from "../../utils";

const tableCellStyle = {
  paddingLeft: "10px",
  paddingRight: "10px",
};
const NOW_TIME = new Date().getTime();
const WEEK_MS = 1000 * 60 * 60 * 24 * 7.0;

function getImpliedCircleStartTime(filled_members) {
  // find circle start time. If facilitator didn't set it manually, assume it's
  // the earliest rating or meeting time on record.
  var all_times = [];
  for (const member of filled_members) {
    if (member.menteeMeetingTime != null) {
      all_times.push(member.menteeMeetingTime.first_timestamp);
    }
    if (
      member.menteeMeetingTime != null &&
      isNaN(member.menteeMeetingTime.first_timestamp)
    ) {
      console.log(`bad user: ${JSON.stringify(member)}`);
    }

    const all_ratings = member.mentee_ratings.concat(member.mentor_ratings);
    for (const rating of all_ratings) {
      all_times.push(rating.meeting_time);

      if (isNaN(rating.meeting_time)) {
        console.log(`bad rating: ${JSON.stringify(rating)}`);
      }
    }
  }

  all_times = all_times.filter((time) => !isNaN(time));

  if (all_times.length == 0) {
    return null;
  }

  return Math.min(...all_times);
}

function createWeekPicker(
  circle_start_time,
  current_week,
  current_week_setter
) {
  const num_weeks = Math.ceil((NOW_TIME - circle_start_time) / WEEK_MS);
  const dropdown_items = [];
  for (let week = 1; week <= num_weeks; week++) {
    let end_date_str = moment(circle_start_time + week * WEEK_MS).format(
      "MMM Do"
    );

    dropdown_items.push(
      <Dropdown.Item key={week} eventKey={week.toString()} as="button">
        Week {week}, ending on {end_date_str}
      </Dropdown.Item>
    );
  }

  if (current_week == -1) {
    current_week = num_weeks;
  }

  return (
    <DropdownButton
      id="dropdown-button"
      title={`Week ${current_week}`}
      variant="primary"
      onSelect={(eventKey) => {
        current_week_setter(parseInt(eventKey));
      }}
    >
      {dropdown_items.reverse()}
    </DropdownButton>
  );
}

function createProgressRows(filled_members, circle_start_time, current_week) {
  const num_weeks = Math.ceil((NOW_TIME - circle_start_time) / WEEK_MS);
  current_week = current_week == -1 ? num_weeks : current_week;
  let week_start = circle_start_time + WEEK_MS * (current_week - 1);
  const week_end = week_start + WEEK_MS;

  const progress_rows = [];
  for (const member of filled_members) {
    const [mentor_ratings, mentee_ratings] = [
      member.mentor_ratings,
      member.mentee_ratings,
    ].map((ratings) =>
      ratings
        .filter((r) => {
          return (
            week_start <= r.meeting_time &&
            r.meeting_time < week_end &&
            r.rating > -1
          );
        })
        .map((m) => m.rating)
    );

    const met_mentor = mentor_ratings.length > 0;
    const met_mentee = mentee_ratings.length > 0;
    // 0 = bad, 1 = ok, 2 = good, -1 = skipped
    const avg_mentor_rating = !met_mentor
      ? null
      : Math.round(
          mentor_ratings.reduce((a, b) => 0.0 + a + b) / mentor_ratings.length
        );
    const mentor_rating_emoji = {
      null: null,
      0: <>&#128543;</>,
      1: <>&#128528;</>,
      2: <>&#128512;</>,
    }[avg_mentor_rating];

    progress_rows.push(
      <tr>
        <td>{member["username"]}</td>
        <td style={{ textAlign: "center" }}>{mentor_rating_emoji}</td>
        <td>
          <div style={{ display: "flex" }}>
            <div>
              <input type="checkbox" disabled checked={met_mentor} />
              {getRelationTitle("mentor", true)}
            </div>
            <div style={{ width: "10px" }}></div>
            <div>
              <input type="checkbox" disabled checked={met_mentee} />
              {getRelationTitle("mentee", true)}
            </div>
          </div>
        </td>
      </tr>
    );
  }
  return <>{progress_rows}</>;
}

export default () => {
  const realmApp = useRealmApp();
  const [members, setMembers] = useState([]);
  const [circleStartTime, setCircleStartTime] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(-1);
  const [weekPicker, setWeekPicker] = useState(<></>);
  const [progressRows, setProgressRows] = useState(<></>);
  React.useEffect(() => {
    loadCircleProgress();
  }, []);

  React.useEffect(() => {
    if (members.length > 0 && circleStartTime != null) {
      console.log("creating progress table");
      setWeekPicker(
        createWeekPicker(circleStartTime, currentWeek, setCurrentWeek)
      );
      setProgressRows(
        createProgressRows(members, circleStartTime, currentWeek)
      );
    }
  }, [members, circleStartTime, currentWeek]);

  async function loadCircleProgress() {
    if (realmApp.app.currentUser) {
      const filled_members = await realmApp.app.currentUser.functions.getCircleProgress();
      const circle = await realmApp.fetchCircle();
      let circle_start_time = getCircleStartTime(circle);
      circle_start_time = circle_start_time
        ? circle_start_time
        : getImpliedCircleStartTime(filled_members);
      setMembers(filled_members);
      setCircleStartTime(circle_start_time);
      console.log(circle_start_time);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5>Circle Progress</h5>
        {weekPicker}
      </div>
      <div style={{ backgroundColor: "#bebebe", padding: "10px" }}>
        <table style={{ width: "100%" }}>
          <tr>
            <th style={tableCellStyle}></th>
            <th style={tableCellStyle}>
              {getRelationTitle("mentor", true)} Score
            </th>
            <th style={tableCellStyle}>Met With</th>
          </tr>
          {progressRows}
        </table>
      </div>
    </div>
  );
};
